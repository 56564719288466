<template>
  <div
    class="modal fade"
    id="exampleModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content pt-3 pb-4 px-4">
        <button
          class="ms-auto btn-close"
          type="button"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
        <div class="modal-header justify-content-center">
          <h5 class="modal-title title" id="exampleModalLabel">詐騙慎防</h5>
        </div>

        <div class="modal-body desc">
          近日詐騙案件增多，曉宅山民宿提醒您， 請勿提供個人資料、金融帳號、信用卡號等，
          並請不要依電話指示進行ATM、網路銀行轉帳操作。
          如有接獲未知來電，請紀錄該電話，並與165反詐騙專線確認。
        </div>
        <div class="modal-footer justify-content-center">
          <button type="button" class="btn btn-outline-dark btn-md" data-dismiss="modal" @click="modal.hide()">確認</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { Modal } from 'bootstrap'
const modalRef = ref(null)
const modal = ref(null)
onMounted(() => {
  modal.value = new Modal(document.getElementById('exampleModal'))

  modal.value.show()
})
</script>
<style lang="scss" scoped>
.title {
  font-size: 24px;
}
.desc {
 font-size: 16px;
 line-height: 27px;
 letter-spacing: 2px;
}
</style>
