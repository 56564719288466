<template>
  <footer>
    <div class="container px-3 px-lg-5">
      <div class="row pb-5 ms-lg-4 ms-md-4">
        <div class="col-12 text-center text-lg-start">
          <router-link to="/">
            <img class="small-logo" :src="`${$imageBase}/logos/small-logo.svg`" alt="" />
          </router-link>
        </div>
      </div>
      <div class="row footer-list ms-lg-4 ms-md-4">
        <div class="col-lg-5 text-center text-lg-start">
          <div class="pb-4" v-for="(hotel, idx) in hotels" :key="`hotel-${idx}`">
            <h5>
              <router-link :to="hotel.to">{{ hotel.name }}</router-link>
            </h5>
            <a :href="hotel.googleMapLink" target="blank">{{ hotel.address }}</a>
          </div>
        </div>
        <div class="col-lg-5 text-center text-lg-start py-5 pt-lg-0 px-1">
          <ul class="list-unstyled">
            <li>櫃檯服務時間 08:00-20:00</li>
            <li>
              <a href="tel:+886(02)2496 0988">+886 2 2496 0988</a>
            </li>
            <li>
              <a href="mailto:contact@daybreaktw.com">contact@daybreaktw.com</a>
            </li>
          </ul>
        </div>
        <div class="col-lg-2 text-center text-lg-start">
          <ul class="list-unstyled">
            <li v-for="(link, lIndx) in pageLinks" :key="`link-${lIndx}`">
              <router-link :to="link.to">{{ link.label }}</router-link>
            </li>
          </ul>
          <div class="text-center text-lg-start">
            <a
              href="https://www.facebook.com/%E6%9B%89%E5%AE%85%E5%B1%B1-Daybreak-109235188520686"
              target="blank"
              ><img :src="`${$imageBase}/icons/fb.svg`" alt=""
            /></a>
            <a href="https://www.instagram.com/daybreak_tw/" target="blank"
              ><img class="ms-3" :src="`${$imageBase}/icons/ig.svg`" alt=""
            /></a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>
const hotels = [
  {
    name: '曉宅山館',
    to: '/daybreak',
    googleMapLink:
      'https://www.google.com/maps/place/No.+49,+Shuqi+Rd,+Ruifang+District,+New+Taipei+City,+224/@25.1095499,121.8431719,17z/data=!3m1!4b1!4m5!3m4!1s0x345d4517c3fc4c0d:0xe86927fee414b4be!8m2!3d25.1095499!4d121.8431719',
    address: '新北市瑞芳區豎崎路49號',
  },
  {
    name: '暮宅山館',
     to: '/latebreak',
    googleMapLink:
      'https://www.google.com/maps/place/224%E6%96%B0%E5%8C%97%E5%B8%82%E7%91%9E%E8%8A%B3%E5%8D%80%E6%B1%BD%E8%BB%8A%E8%B7%AF93%E8%99%9F/@25.10958,121.8408142,17z/data=!3m1!4b1!4m5!3m4!1s0x345d4517c43e0851:0x10273a43aaf75f21!8m2!3d25.10958!4d121.8430029',
    address: '新北市瑞芳區汽車路93號',
  },
  {
    name: '墨旅館',
     to: '/darkbreak',
    googleMapLink:
      'https://www.google.com/maps/place/No.+20-1,+Shuqi+Rd,+Ruifang+District,+New+Taipei+City,+224/@25.1094199,121.8406245,17z/data=!3m1!4b1!4m6!3m5!1s0x345d4517c2f20577:0x420fe50896642da5!8m2!3d25.1094151!4d121.8431994!16s%2Fg%2F11c2cg9h54?entry=ttu',
    address: '新北市瑞芳區豎崎路20之1號',
  },
]

const pageLinks = [
  {
    label: '線上訂房',
    to: '/booking-rule',
  },
  {
    label: '探索曉宅山',
    to: '/about',
  },
  {
    label: '最新消息',
    to: '/news',
  },
  {
    label: '聯絡我們',
    to: '/contact',
  },
  {
    label: '隱私權政策',
    to: '/privacy',
  },
]
</script>

<style scoped lang="scss">
$SansTC: 'Noto Sans TC', sans-serif;
$Serif: serif, 'Noto Serif TC';
// * {
//   border: 1px solid #000;
// }
footer {
  font-family: $Serif;
  padding: 100px 0;

  background-color: #333;
  color: white;

  h5 {
    letter-spacing: 10px;
    padding-bottom: 7px;
  }
  li {
    padding-bottom: 15px;
    letter-spacing: 5px;
  }
  a {
    // border: 1px solid rgb(255, 0, 0);
    padding: 7px 0;
    letter-spacing: 5px;
    color: white;
    text-decoration: none;
    transition: letter-spacing 0.2s;

    &:hover {
      opacity: 0.6;
    }
  }
  .community {
    a {
      margin-right: 10px;
    }
  }
}

@media (max-width: 575.99px) {
  footer {
    h5 {
      font-size: 16px;
    }

    .small-logo {
      width: 150px;
    }
  }
}
</style>
