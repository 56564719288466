import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Landing'),
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/About'),
  },
  {
    path: '/daybreak',
    name: 'RoomsDaybreak',
    component: () => import('@/views/RoomsDaybreakList'),
  },
  {
    path: '/latebreak',
    name: 'RoomsLatebreak',
    component: () => import('@/views/RoomsLatebreakList'),
  },
  {
    path: '/darkbreak',
    name: 'RoomsDarkbreak',
    component: () => import('@/views/RoomsDarkbreakList'),
  },
  {
    path: '/darkbreak/:id',
    name: 'RoomDarkbreak',
    meta: {
      scrollBehavior: true,
    },
    component: () => import('@/views/RoomDarkbreak'),
  },
  {
    path: '/latebreak/:id',
    name: 'RoomLatebreak',
    meta: {
      scrollBehavior: true,
    },
    component: () => import('@/views/RoomLatebreak'),
  },
  {
    path: '/booking-rule',
    name: 'BookingRule',
    component: () => import('@/views/BookingRule'),
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import('@/views/Privacy'),
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('@/views/Contact'),
  },
  {
    path: '/news',
    name: 'News',
    component: () => import('@/views/News'),
  },
  {
    path: '/news/:id',
    name: 'NewsArticle',
    component: () => import('@/views/NewsArticle'),
  },
  {
    path: '/daybreak/:id',
    name: 'RoomDaybreak',
    meta: {
      scrollBehavior: true,
    },
    component: () => import('@/views/RoomDaybreak'),
  },
  {
    path: '/booking-system-5/:catchAll(.*)*',
    redirect: '/',
  },
  // {
  //   path: '/:catchAll(.*)*',
  //   redirect: '/'
  // },
  // {
  //   path: '/:catchAll(.*)*/:catchAll(.*)*',
  //   redirect: '/'
  // },
  // {
  //   path: '/:catchAll(.*)*/:catchAll(.*)*/:catchAll(.*)*',
  //   redirect: '/'
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (!to.meta?.scrollBehavior) return { x: 0, y: 0 }
  },
  routes,
})

router.afterEach((to, from, next) => {
  window.scrollTo({
    top: 0,
    behavior: 'instant',
  })
})

export default router
