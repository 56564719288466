<template>
  <nav class="navbar" :class="{ differentColor: isIntoWhiteBg && allTransitionend && !navState }">
    <div class="menu-btn" :class="{ 'menu-btn_active': navState }" @click="onNavClick">
      <span></span>
    </div>
    <router-link to="/booking-rule" @click="navState = false">
      線上訂房<span class="booking-hide"> Booking</span>
    </router-link>
    <div
      class="offcanvas offcanvas-start w-100 text-white nav"
      data-bs-backdrop="false"
      tabindex="-1"
      id="sideNav"
      aria-labelledby="offcanvasScrollingLabel"
    >
      <div class="offcanvas-body p-0 row">
        <div class="menu-bg d-none d-md-block col-0 col-md-4"></div>
        <div class="column menu-wrapper justify-conten-center col-12 col-md-8">
          <div class="menu">
            <section class="column menu-rooms">
              <router-link class="" to="/daybreak" @click="onNavClick">
                <img :src="`${$imageBase}/logos/daybreak-white.svg`" alt="" />
              </router-link>

              <router-link class="" to="/latebreak" @click="onNavClick">
                <img :src="`${$imageBase}/logos/latebreak-white.svg`" alt="" />
              </router-link>

              <router-link class="" to="/darkbreak" @click="onNavClick">
                <img :src="`${$imageBase}/logos/darkbreak-white.svg`" alt="" />
              </router-link>
            </section>

            <section class="column menu-info">
              <router-link to="/about" @click="onNavClick">
                探索曉宅山<span class="menu-info-en"> About Daybreak</span>
              </router-link>
              <router-link to="/news" @click="onNavClick">
                最新消息<span class="menu-info-en"> News</span>
              </router-link>
              <router-link to="/contact" @click="onNavClick">
                聯絡我們<span class="menu-info-en"> Contact Us</span>
              </router-link>
            </section>
          </div>

          <router-link class="footer" to="/" @click="onNavClick">
            <img :src="`${$imageBase}/logos/small-logo.svg`" alt="" />
          </router-link>
        </div>
      </div>
    </div>
  </nav>
</template>

<script setup>
import emitter from '@/utils/mitt.js'
import { ref, nextTick, onMounted, provide, getCurrentInstance } from 'vue'
import { useRouter } from 'vue-router'

const navState = ref(false)
const isPreload = ref(true)
const $router = useRouter()
let bsOffcanvas = null
const allTransitionend = ref(true)
const isIntoWhiteBg = ref(false)

const { proxy } = getCurrentInstance()
const { $bootstrap } = proxy
onMounted(async () => {
  await nextTick()
  const bootstrap = $bootstrap
  var myOffcanvas = document.getElementById('sideNav')
  myOffcanvas.addEventListener('transitionend', () => (allTransitionend.value = true))
  bsOffcanvas = new bootstrap.Offcanvas(myOffcanvas)
  emitter.on('changeMenuColor', (boolean) => {
    changeMenuColor(boolean)
  })
})
const changeMenuColor = (boolean) => {
  isIntoWhiteBg.value = boolean
}

const onNavClick = () => {
  if (!bsOffcanvas) return
  navState.value = !navState.value
  allTransitionend.value = false

  bsOffcanvas.toggle()
}
const navToPage = (path) => {
  onNavClick()
}
</script>

<style scoped lang="scss">
@media (max-width: 575.99px) {
  nav .booking-hide {
    display: none;
  }
}

.comming-soon {
  right: 0;
  bottom: -10px;
  padding: 2px 0px 2px 8px;
  font-size: 12px;
  font-weight: 900;
  background: #fff;
  color: #000;
  border-radius: 20px;
}

a {
  display: inline-block;
  color: #fff;
}

.differentColor {
  mix-blend-mode: difference;
}
</style>
