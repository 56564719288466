import { createApp } from 'vue'
import App from './App.vue'
import * as bootstrap from 'bootstrap';
// import './registerServiceWorker'
import VueLazyload from 'vue-lazyload'
import 'animate.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/style/style.scss'
import router from './router'

const app =createApp(App).use(router)

app.config.globalProperties.$imageBase = process.env.VUE_APP_IMAGE_URL
app.config.globalProperties.$bootstrap = bootstrap

app.use(VueLazyload, {
  preLoad: 1.3,
  // error: require('./assets/img/error.jpg'),
  // loading: require('./assets/img/homePage_top.jpg'),
  attempt: 2,
})

app.mount('#app')
